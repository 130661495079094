import {
  GAME_IS_CLOSED,
  GAME_IS_LOADED,
  GAME_IS_LOADING,
  GET_SUPPORTED_CURRENCIES_FAILED,
  GET_SUPPORTED_CURRENCIES_SUCCESS,
  SCROLL,
} from "../types/platformTypes";

export function getSupportedCurrenciesSuccess(currencies) {
  return {
    type: GET_SUPPORTED_CURRENCIES_SUCCESS,
    currencies,
  };
}

export function getSupportedCurrenciesSuccessFailed(error) {
  return {
    type: GET_SUPPORTED_CURRENCIES_FAILED,
    error,
  };
}

export function gameIsLoading() {
  return {
    type: GAME_IS_LOADING,
  };
}

export function gameIsLoaded() {
  return {
    type: GAME_IS_LOADED,
  };
}

export function gameIsClosed() {
  return {
    type: GAME_IS_CLOSED,
  };
}

export function scroll(scrollPosition) {
  return {
    type: SCROLL,
    visible: true,
    scrollPosition,
  };
}
