import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./App.module.css";
import { history } from "./history/history";
import Layout from "./hoc/Layout/Layout";
import MainPagesRoutes from "./routes/MainPagesRoutes";
import { LocalStorageService } from "./services/LocalStorageService";
import { SocketModel } from "./socket/socketModel";
import { checkIsMobile } from "./store/effects/deviceEffects";
import {
  addPostMessagesFromChildrenAppHandler,
  getSupportedCurrencies,
  removePostMessagesFromChildrenAppHandler,
} from "./store/effects/platformEffects";
import { getPlayer } from "./store/effects/userEffects";

const socketModel = new SocketModel();

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [accessToken, setAccessToken] = useState("");

  history.navigate = useNavigate();
  history.location = useLocation();

  useEffect(() => {
    dispatch(getSupportedCurrencies());
    dispatch(checkIsMobile());
    dispatch(addPostMessagesFromChildrenAppHandler());

    return () => removePostMessagesFromChildrenAppHandler();
  }, []);

  useEffect(() => {
    if (user) {
      socketModel.connect(user, () => dispatch(getPlayer()));
    }
  }, [user]);

  useEffect(() => {
    getPlayerInfo();
  }, [accessToken]);

  const getPlayerInfo = () => {
    setAccessToken(LocalStorageService.getToken());
    accessToken && dispatch(getPlayer());
  };

  return (
    <div className={classes.App}>
      <Layout>
        <MainPagesRoutes />
      </Layout>
    </div>
  );
};

export default App;
