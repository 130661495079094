import {
  gameIsClosed,
  gameIsLoaded,
  scroll,
} from "../store/actions/platformActions";
import {
  GAME_IS_CLOSED,
  GAME_IS_LOADED,
  GAME_IS_LOADING,
  SCROLL,
} from "../store/types/platformTypes";

export class PostMessageProcessorFactory {
  static createHandler(dispatch) {
    return (data) => {
      switch (data.message) {
        case GAME_IS_LOADING:
          dispatch(gameIsLoaded());
          break;
        case GAME_IS_LOADED:
          dispatch(gameIsLoaded());
          break;
        case GAME_IS_CLOSED:
          dispatch(gameIsClosed());
          break;
        case SCROLL:
          if (data.scrollPosition !== undefined) {
            dispatch(scroll(data.scrollPosition));
          }
          break;
        default:
          console.warn(`Unknown message type: ${data.message}`);
          break;
      }
    };
  }
}
