import { setIsMobile } from "../actions/deviceActions";

export function checkIsMobile() {
  return (dispatch) => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 960;

      dispatch(setIsMobile(isMobile));
    };

    window.addEventListener("resize", handleResize);

    window.addEventListener("unload", () => {
      window.removeEventListener("resize", handleResize);
    });
  };
}
