import {
  GAME_IS_CLOSED,
  GAME_IS_LOADED,
  GAME_IS_LOADING,
  GET_SUPPORTED_CURRENCIES_FAILED,
  GET_SUPPORTED_CURRENCIES_SUCCESS,
  SCROLL,
} from "../types/platformTypes";

const initialState = {
  currencies: null,
  isGameLoading: false,
  isGameLoaded: false,
  headerScrollPosition: 0,
  error: null,
};

export default function platformReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPORTED_CURRENCIES_SUCCESS:
      return { ...state, currencies: action.currencies };
    case GET_SUPPORTED_CURRENCIES_FAILED:
      return { ...state, error: action.error };
    case GAME_IS_LOADING:
      return { ...state, isGameLoaded: false, isGameLoading: true };
    case GAME_IS_LOADED:
      return { ...state, isGameLoaded: true, isGameLoading: false };
    case GAME_IS_CLOSED:
      return { ...state, isGameLoaded: false, isGameLoading: false };
    case SCROLL:
      return {
        ...state,
        headerScrollPosition: action.scrollPosition,
      };
    default:
      return { ...state };
  }
}
