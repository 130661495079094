import { SET_IS_MOBILE } from "../types/deviceTypes";

const initialState = {
  isMobile: window.innerWidth <= 960,
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
}
