import { axiosConfig } from "../../axios/axios-api";
import { PostMessageProcessorFactory } from "../../factories";
import {
  getSupportedCurrenciesSuccess,
  getSupportedCurrenciesSuccessFailed,
} from "../actions/platformActions";

export function getSupportedCurrencies() {
  return async (dispatch) => {
    try {
      const { data } = await axiosConfig.get(`platform/supportedCurrencies`);
      dispatch(getSupportedCurrenciesSuccess(data));
    } catch (error) {
      dispatch(getSupportedCurrenciesSuccessFailed(error));
    }
  };
}

export function addPostMessagesFromChildrenAppHandler() {
  return (dispatch) => {
    const handleMessage = ({ data }) => {
      if (!data.message) return;

      const handleSpecificMessage =
        PostMessageProcessorFactory.createHandler(dispatch);

      handleSpecificMessage(data);
    };

    window.addEventListener("message", handleMessage);

    addPostMessagesFromChildrenAppHandler._handler = handleMessage;
  };
}

export function removePostMessagesFromChildrenAppHandler() {
  const handler = addPostMessagesFromChildrenAppHandler._handler;
  if (handler) {
    window.removeEventListener("message", handler);
    addPostMessagesFromChildrenAppHandler._handler = null;
  }
}
