import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import classes from "./Layout.module.css";

const Layout = (props) => {
  const state = {
    isLogged: false,
  };
  const [headerVisibility, setHeaderVisibility] = useState(true);

  const { isGameLoaded, isGameLoading, headerScrollPosition } = useSelector(
    (state) => state.platform
  );
  const { isMobile } = useSelector((state) => state.device);

  useEffect(() => {
    if (isMobile) {
      if (isGameLoaded || isGameLoading || headerScrollPosition >= 30) {
        return setHeaderVisibility(false);
      }
    }

    setHeaderVisibility(true);
  }, [isMobile, isGameLoaded, headerScrollPosition]);

  return (
    <>
      <div
        className={
          headerVisibility ? classes.Layout : classes.LayoutWithoutHeader
        }
      >
        <Header
          headerVisibility={headerVisibility}
          className={classes.Header}
          isLogged={state.isLogged}
        />

        <main className={classes.Main}>{props.children}</main>
      </div>
    </>
  );
};

export default Layout;
